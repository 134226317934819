
.plan-card-holder{
  margin-left : 2%;
  width : 30%;
  display: inline-block;
  height : 500px;
  cursor : pointer;
}

@media (max-width: 950px){
/*Mobile*/
  .plan-card-holder{
    margin : 4%;
    width : 90%;
  }
}
