.BetaNewsCard {
  width: 21%;
  margin: 1%;
  display: inline-block;

}
.MobileHolder{
  display : none;
}
.image{
  width : 91%;
  margin : 2%;
}
@media (max-width: 1000px){
  .image{
    width : 70%;
    margin-left : 15%;
    margin-top : 10%;
  }
  .BetaNewsCard {
    width: 45%;
    margin: 1%;
  }
}
@media (max-width: 600px){
  .NewsHolderScreen{
    display : none;
  }
  .BetaNewsCard {
    width: 95%;
    margin: 1%;
  }

  .MobileHolder{
    display : block;
    width : 99%;
    margin : auto;
  }
}
