.mobile{
  display : none;
}

@media (max-width: 950px){
  .fullscreen{
    display : none;
  }
  .mobile{
    display : block;
    z-index : 1000;
  }
}

::-ms-clear {
  display: none;
}
